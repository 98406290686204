import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import api from '../../service/api';
import { useNavigate, useParams } from "react-router";
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarRecepcionista() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [recepcionista, setRecepcionista] = useState([]);

    useEffect(() => {
        api.get('recepcionista/' + id).then(({ data }) => {
            setRecepcionista(data);
        });
    }, []);

    async function deleteRecepcionista(id) {
        api
            .delete("recepcionista/" + id, {
                id: id
            })
            .then(() => {
                alert("Recepcionista inativado!");
                navigate("/recepcionistas", { replace: true });
            });
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home > Lista de Recepcionista > Perfil Recepcionista
            </div>
            <div className={styles.title}>
                Perfil Recepcionista
            </div>
            <div className={styles.divButton}>
                <Link to={`/editarRecepcionista/${recepcionista.id}`}>
                    <Button size="sm" className={styles.buttonEditar}><MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar</Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <div className={styles.divDadosRecepcionista}>
                    <Row xs="3">
                        <Col >
                            Nome <p className={styles.p}>{recepcionista.nome}</p>
                        </Col>

                        <Col >
                            Nome Social <p className={styles.p}>{recepcionista.nome_social}</p>
                        </Col>
                        <Col >
                            Data de Nascimento <p className={styles.p}>{recepcionista.data_nascimento}</p>
                        </Col>
                        <Col >
                            Gênero <p className={styles.p}>{recepcionista.genero}</p>
                        </Col>
                        <Col >
                            CPF <p className={styles.p}>{recepcionista.cpf}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
                </div>
                <div className={styles.linha} />
                <div className={styles.divContatoRecepcionista}>

                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{recepcionista.telefone}</p>
                        </Col>
                        <Col xs="6" >
                            E-mail <p className={styles.p}>{recepcionista.email}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default VisualizarRecepcionista;