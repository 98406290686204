import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import ModalPacientesComponent from '../../components/ModalPacientes/ModalPacientesComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye, AiOutlineArrowRight } from 'react-icons/ai';
import api from '../../service/api';

function Questionarios() {

    const [questionarios, setQuestionarios] = useState([]);
    const [questionariosFiltrados, setQuestionariosFiltrados] = useState([]);
    const [busca, setBusca] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [questionario, setQuestionario] = useState(null);

    useEffect(() => {
        api.get('questionarios').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setQuestionarios(dataSort);
        });
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = questionarios.filter((questionario) => questionario.nome.toLocaleLowerCase().includes(lowerBusca));
        setQuestionariosFiltrados(filtrados);
    }, [busca]);

    const enviarQuestionario = (q) => {
        setQuestionario(q);
        setIsOpen(true);
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            {isOpen ? <ModalPacientesComponent questionario={`${questionario}`} /> : null}
            <div className={styles.route}>
                Home > Questionários
            </div>
            <div className={styles.title}>
                Questionários
            </div>
            <div className={styles.divButtonEnvQuestionario}>
                <Button size="sm" className={styles.buttonEnvQuestionario} onClick={() => setIsOpen(true)}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Questionário Padrão</Button>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <Input size="sm" placeholder="Nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>

                        </Row>
                    </InputGroup>

                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                Questionário
                            </th>
                            <th>
                                Visualizar
                            </th>
                            <th>
                                Enviar
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {busca ?
                            questionariosFiltrados.map((questionario) => (
                                <tr>
                                    <td className={styles.tbody}>
                                        {questionario.nome}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarQuestionario/${questionario.id}`}><Button size="sm" className={styles.buttonVerQuestionario}><AiOutlineEye size={17} className={styles.buttonIcon} />Visualizar</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={() => enviarQuestionario(questionario.id)} className={styles.buttonEnvQuestionario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Questionário</Button>
                                    </td>
                                </tr>
                            ))
                            :
                            questionarios.map((questionario) => (
                                <tr>
                                    <td className={styles.tbody}>
                                        {questionario.nome}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarQuestionario/${questionario.id}`}><Button size="sm" className={styles.buttonVerQuestionario}><AiOutlineEye size={17} className={styles.buttonIcon} />Visualizar</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" className={styles.buttonEnvQuestionario} onClick={() => enviarQuestionario(questionario.id)}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Questionário</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>

        </div >
    );
}

export default Questionarios;