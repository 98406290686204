import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import api from '../../service/api';
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Table, Input, Button, InputGroup, Col, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';

function Recepcionistas() {

    const navigate = useNavigate();

    const [recepcionistas, setRecepcionistas] = useState([]);
    const [recepcionistasFiltradas, setRecepcionistasFiltradas] = useState([]);
    const [statusRecepcionista, setStatusRecepcionista] = useState("ativo");
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('recepcionista').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setRecepcionistas(dataSort);
        });
    }, []);

    async function deleteRecepcionista(id) {
        api
            .delete("recepcionista/" + id, {
                id: id
            })
            .then(() => {
                alert("Recepcionista inativado!");
                navigate("/recepcionistas", { replace: true });
            });
    }


    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = recepcionistas.filter((recepcionista) => recepcionista.nome.toLocaleLowerCase().includes(lowerBusca));
        setRecepcionistasFiltradas(filtrados);
    }, [busca]);

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            <div className={styles.route}>
                Home > Lista de Recepcionistas
        </div>
            <div className={styles.title}>
                Lista de Recepcionistas
        </div>
            <div responsive className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input size="sm" placeholder="Nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>

                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input value={statusRecepcionista} onChange={(ev) => setStatusRecepcionista(ev.target.value)} name="select" type="select" size="sm" className={styles.selectFiltro}>
                                            <option value="ativo">
                                                Ativo
                        </option>
                                            <option value="inativo">
                                                Inativo
                        </option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>
                                            Filtro
                        </InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>

                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                Recepcionista
                        </th>
                            <th>
                                E-mail
                        </th>
                            <th>
                                CPF
                        </th>
                            <th>
                                Ações
                        </th>
                        </tr>
                    </thead>

                    <tbody>
                        {busca ?
                            recepcionistasFiltradas.filter(r => r.status === statusRecepcionista).map((recepcionista) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {recepcionista.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {recepcionista.email}
                                    </td>
                                    <td className={styles.tbody}>
                                        {recepcionista.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarRecepcionista/${recepcionista.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver Perfil</Button></Link>
                                    </td>
                                </tr>
                            ))
                            :
                            recepcionistas.filter(r => r.status === statusRecepcionista).map((recepcionista) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {recepcionista.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {recepcionista.email}
                                    </td>
                                    <td className={styles.tbody}>
                                        {recepcionista.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarRecepcionista/${recepcionista.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver Perfil</Button></Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </Table>
            </div>

        </div >
    );
}

export default Recepcionistas;