import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import api from '../../service/api';
import { useNavigate, useParams } from "react-router";
import styles from "./index.module.css";
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarPaciente() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [paciente, setPaciente] = useState([]);

    useEffect(() => {
        api.get('paciente/' + id).then(({ data }) => {
            setPaciente(data);
        });
    }, []);

    async function deletePaciente(id) {
        api
            .delete("paciente/" + id, {
                id: id
            })
            .then(() => {
                alert("Paciente inativado!");
                navigate("/pacientes", { replace: true });
            });
    }


    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home > Lista de Pacientes > Perfil Paciente
            </div>
            <div className={styles.title}>
                Perfil Paciente
            </div>
            <div className={styles.divButton}>
                <Link to={`/editarPaciente/${paciente.id}`}>
                    <Button size="sm" className={styles.buttonEditar}><MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar</Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <div className={styles.divDadosPaciente}>
                    <Row xs="3" >
                        <Col >
                            Nome <p className={styles.p}>{paciente.nome}</p>
                        </Col>

                        <Col >
                            Nome Social <p className={styles.p}>{paciente.nome_social}</p>
                        </Col>
                        <Col >
                            Data de Nascimento <p className={styles.p}>{paciente.data_nascimento}</p>
                        </Col>
                        <Col >
                            Gênero <p className={styles.p}>{paciente.genero}</p>
                        </Col>
                        <Col >
                            CPF <p className={styles.p}>{paciente.cpf}</p>
                        </Col>
                        <Col >
                            Estado Civil <p className={styles.p}>{paciente.estado_civil}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
                </div>
                <div className={styles.linha} />
                <div className={styles.divContatoPaciente}>

                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{paciente.telefone}</p>
                        </Col>
                        <Col xs="6" >
                            E-mail <p className={styles.p}>{paciente.email}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default VisualizarPaciente;