import React from 'react';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import LoadingComponent from '../../components/Loading/LoadingComponent';
import styles from './index.module.css';

function Home() {
    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            <LoadingComponent />
            <div>
            </div >
        </div >
    );
}

export default Home;