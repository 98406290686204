import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import api from '../../service/api';
import { useNavigate, useParams } from "react-router";
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarProfissional() {

    const { id } = useParams();

    const navigate = useNavigate();

    const [profissionalSaude, setProfissionalSaude] = useState([]);

    useEffect(() => {
        api.get('profissionalSaude/' + id).then(({ data }) => {
            setProfissionalSaude(data);
        });
    }, []);

    async function deleteProfissional(id) {
        api
            .delete("profissionalSaude/" + id, {
                id: id
            })
            .then(() => {
                alert("Profissional inativado!");
                navigate("/profissionaisSaude", { replace: true });
            });
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home > Lista de Profissionais da Saúde > Perfil Profissional da Saúde
        </div>
            <div className={styles.title}>
                Perfil Profissional da Saúde
        </div>
            <div className={styles.divButton}>
                <Link to={`/editarProfissional/${profissionalSaude.id}`}>
                    <Button size="sm" className={styles.buttonEditar}><MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar</Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
            </div>
                <div className={styles.linha} />
                <div className={styles.divDadosProfissional}>
                    <Row xs="3" >
                        <Col >
                            Nome <p className={styles.p}>{profissionalSaude.nome}</p>
                        </Col>

                        <Col >
                            Nome Social <p className={styles.p}>{profissionalSaude.nome_social}</p>
                        </Col>
                        <Col >
                            Data de Nascimento <p className={styles.p}>{profissionalSaude.data_nascimento}</p>
                        </Col>
                        <Col >
                            Gênero <p className={styles.p}>{profissionalSaude.genero}</p>
                        </Col>
                        <Col >
                            CPF <p className={styles.p}>{profissionalSaude.cpf}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
            </div>
                <div className={styles.linha} />
                <div className={styles.divContatoProfissional}>

                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{profissionalSaude.telefone}</p>
                        </Col>
                        <Col xs="6" >
                            E-mail <p className={styles.p}>{profissionalSaude.email}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divDadosProfissionais}>
                    Dados Profissionais
            </div>
                <div className={styles.linha} />
                <div className={styles.divInfoProfissional}>

                    <Row xs="3">
                        <Col >
                            Especialidade <p className={styles.p}>{profissionalSaude.especialidade}</p>
                        </Col>
                        <Col >
                            Conselho Regional <p className={styles.p}>{profissionalSaude.conselho_regional}</p>
                        </Col>
                        <Col >
                            UF Consolhe Regional <p className={styles.p}>{profissionalSaude.uf_conselho_regional}</p>
                        </Col>
                        <Col >
                            Registro Profissional <p className={styles.p}>{profissionalSaude.registro_profissional}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default VisualizarProfissional;