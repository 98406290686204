import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { useEffect, useState } from 'react';
import api from '../../service/api';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import ModalQuestionariosComponent from '../../components/ModalQuestionarios/ModalQuestionariosComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye, AiOutlineArrowRight } from 'react-icons/ai';

function Pacientes() {

    const navigate = useNavigate();

    const [pacientes, setPacientes] = useState([]);
    const [pacientesFiltrados, setPacientesFiltratos] = useState([]);
    const [statusPaciente, setStatusPaciente] = useState("ativo");
    const [busca, setBusca] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [paciente, setPaciente] = useState("");
    const [pacienteTelefone, setPacienteTelefone] = useState("");

    useEffect(() => {
        api.get('paciente').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.nome < obj2.nome ? -1 :
                    (obj1.nome > obj2.nome ? 1 : 0);
            });
            setPacientes(dataSort);
        });
    }, []);

    async function deletePaciente(id) {
        api
            .delete("paciente/" + id, {
                id: id
            })
            .then(() => {
                alert("Paciente inativado!");
                navigate("/pacientes", { replace: true });
            });
    }

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = pacientes.filter((paciente) => paciente.nome.toLocaleLowerCase().includes(lowerBusca));
        setPacientesFiltratos(filtrados);
    }, [busca]);


    const enviarQuestionario = (p, t) => {
        setPaciente(p);
        setPacienteTelefone(t);
        setIsOpen(true);
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            {isOpen ? <ModalQuestionariosComponent paciente={`${paciente}`} telefone={`${pacienteTelefone}`} /> : null}

            <div className={styles.route}>
                Home > Lista de Pacientes
            </div>
            <div className={styles.title}>
                Lista de Pacientes
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input size="sm" placeholder="Nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input value={statusPaciente} onChange={evento => setStatusPaciente(evento.target.value)} name="select" type="select" size="sm" className={styles.selectFiltro}>
                                            <option value="ativo">
                                                Ativo
                                            </option>
                                            <option value="inativo">
                                                Inativo
                                            </option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>
                                            Filtro
                            </InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>

                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>
                                Paciente
                            </th>
                            <th>
                                Data de Nascimento
                            </th>
                            <th>
                                CPF
                            </th>
                            <th>
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {busca ?
                            pacientesFiltrados.filter(p => p.status === statusPaciente).map((paciente) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {paciente.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {paciente.data_nascimento}
                                    </td>
                                    <td className={styles.tbody}>
                                        {paciente.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarPaciente/${paciente.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver perfil</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={() => enviarQuestionario(paciente.id, paciente.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Formulário</Button>
                                    </td>
                                </tr>
                            ))
                            :
                            pacientes.filter(p => p.status === statusPaciente).map((paciente) => (
                                <tr >
                                    <td className={styles.tbody}>
                                        {paciente.nome}
                                    </td>
                                    <td className={styles.tbody}>
                                        {paciente.data_nascimento}
                                    </td>
                                    <td className={styles.tbody}>
                                        {paciente.cpf}
                                    </td>
                                    <td>
                                        <Link to={`/visualizarPaciente/${paciente.id}`}><Button size="sm" className={styles.buttonPerfil}><AiOutlineEye size={17} className={styles.buttonIcon} />Ver perfil</Button></Link>
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={() => enviarQuestionario(paciente.id, paciente.telefone)} className={styles.buttonFormulario}><AiOutlineArrowRight size={17} className={styles.buttonIcon} />Enviar Questionário</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>

        </div >
    );
}

export default Pacientes;