import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import styles from './index.module.css';
import { ListGroup, ListGroupItem } from 'reactstrap';
import api from '../../service/api';

function VisualizarQuestionario() {
    const { id } = useParams();
    const [questionario, setQuestionario] = useState([]);
    const [nomeQuestionario, setNomeQuestionario] = useState("");
    useEffect(() => {
        api.get('questionarios/' + id).then(({ data }) => {
            setQuestionario(data);
            setNomeQuestionario(data[0].nome);
        });
    }, []);

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home > Lista de Questionário > Questionário
            </div>
            <div className={styles.title}>
                Questionário {nomeQuestionario}
            </div>
            <div responsive className={styles.container}>
                <ListGroup
                    flush
                    numbered
                >
                    {questionario.map((questionario) => (
                        <ListGroupItem className={styles.listItem}>
                            {questionario.pergunta}
                        </ListGroupItem>
                    ))}
                </ListGroup>

            </div>

        </div >
    );
}

export default VisualizarQuestionario;