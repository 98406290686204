import styles from "./NavMenuComponent.module.css";
import { UncontrolledDropdown, NavItem, Nav, NavLink, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MdOutlineDashboard, MdOutlinePeople } from "react-icons/md";
import { FaRegClipboard } from "react-icons/fa";
import { GiOfficeChair, GiHealing } from "react-icons/gi"

function NavMenuComponent() {

    return (
        <div className={styles.mainDiv}>
            <div className>
                <Nav>
                    <NavItem >
                        <NavLink href="/home" active className={styles.navDashboard}>
                            <MdOutlineDashboard className={styles.iconDashboard} />
                                Dashboard
                        </NavLink>
                    </NavItem>

                    <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle caret nav className={styles.navPaciente}>
                            <MdOutlinePeople className={styles.iconPaciente} />
                            Paciente
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/pacientes" className={styles.itensDropdown}>
                                Listar
                            </DropdownItem>
                            <DropdownItem href="/cadastroPaciente" className={styles.itensDropdown}>
                                Cadastrar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle caret nav className={styles.navRecepcionista}>
                            <GiOfficeChair className={styles.iconRecepcionista} />
                            Recepcionista
                        </DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem href="/recepcionistas" className={styles.itensDropdown}>
                                Listar
                            </DropdownItem>
                            <DropdownItem href="/cadastroRecepcionista" className={styles.itensDropdown}>
                                Cadastrar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle caret nav className={styles.navProfissionalSaude}>
                            <GiHealing className={styles.iconProfissionalSaude} />
                            Profissional da Saúde
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/profissionaisSaude" className={styles.itensDropdown}>
                                Listar
                            </DropdownItem>
                            <DropdownItem href="/cadastroProfissionalSaude" className={styles.itensDropdown}>
                                Cadastrar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <NavItem>
                        <NavLink href="/questionarios" className={styles.navListaQuestionario}>
                            <FaRegClipboard className={styles.iconQuestionario} />
                            Questionários
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        </div>
    );
};

export default NavMenuComponent;