import React, { useState } from "react";
import styles from "./ModalEnvioQuestionarioComponent.module.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { MdOutlineFactCheck } from 'react-icons/md';
import api from '../../service/api';
import logoHB4 from '../../assets/images/hb4_principal.png'

function ModalEnvioQuestionario(props) {

    const [isOpen, setIsOpen] = useState(true);
    const respostas = props.respostas;

    const handleSubmit = (respostas) => {
        api.post('perguntas/', {
            respostas
        }).then((response) => {
            setIsOpen(false);
            window.location.assign("https://www.google.com");
        });

    }

    return (
        <div>
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader className={styles.modalHeader}>
                    <img src={logoHB4} className={styles.logoHB4} />
                </ModalHeader>
                <ModalBody>
                    <div className={styles.icon} >
                        <MdOutlineFactCheck size="80" className={styles.icon} />
                    </div>
                    <div className={styles.divText}>
                        Obrigado por responder o questionário!
                    </div>
                </ModalBody>
                <ModalFooter className={styles.modalFooter}>
                    <Button size="sm" onClick={() => handleSubmit(respostas)} className={styles.button}>
                        Enviar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalEnvioQuestionario;