import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import styles from './index.module.css';
import { Navbar, NavbarBrand, Progress, Button } from 'reactstrap';
import logoHb4RedColorida from '../../assets/images/hb4_reduzida_colorida.png';
import api from '../../service/api';
import Respostas from '../../components/Respostas/RespostasComponent.js';
import ModalEnvioQuestionario from '../../components/ModalEnvioQuestionario/ModalEnvioQuestionarioComponent.js';
import useDidUpdateEffect from '../../components/UseDidUpdateEffect/useDidUpdateEffect.ts';


function Perguntas(props) {

    const [qtdPerguntas, setQtdPerguntas] = useState(0);
    const [perguntaAtual, setPerguntaAtual] = useState(0);
    const [respostaSelecionanda, setRespostaSelecionada] = useState("");
    const [respostas, setRespostas] = useState([]);
    const [perguntas, setPerguntas] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        api.get('perguntas/' + id).then(({ data }) => {

            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            setPerguntas(dataSort);
            setQtdPerguntas(dataSort.length);
            const profissionalSaude_Paciente = { profissional_saude_paciente: id };
            setRespostas([...respostas, profissionalSaude_Paciente]);
            setPerguntaAtual(1);
        });
    }, []);

    useDidUpdateEffect(() => {
        if (respostas.length === 1) {
            return null;
        } if (perguntas.length < 90) {
            setPerguntaAtual(perguntaAtual + 1);
            setRespostaSelecionada("");
        } else {
            if (perguntas[perguntaAtual - 1].pergunta_chave === true) {
                if (respostas[respostas.length - 1].resposta === "Quase sempre" || respostas[respostas.length - 1].resposta === "Sempre") {
                    setPerguntaAtual(perguntas[perguntaAtual - 1].proxima_pergunta_sim);
                    setRespostaSelecionada("");
                } else {
                    setPerguntaAtual(perguntas[perguntaAtual - 1].proxima_pergunta_nao);
                    setRespostaSelecionada("");
                }
            } else {
                setPerguntaAtual(perguntaAtual + 1);
                setRespostaSelecionada("");
            }
        }
    }, [respostas]);

    const getResposta = (value) => {
        setRespostaSelecionada(value);
    }

    const salvaResposta = () => {
        const res = { pergunta_id: perguntas[perguntaAtual - 1].id, resposta: respostaSelecionanda };
        setRespostas([...respostas, res]);
    }

    const handleSubmit = (respostas) => {
        return <ModalEnvioQuestionario respostas={respostas} />
    }

    return (
        <div className={styles.mainDiv}>
            <Navbar expand="sm" className={styles.navbar} >
                <NavbarBrand>
                    <div className={styles.divImage}>
                        <img src={logoHb4RedColorida} className={styles.image} />
                    </div>
                </NavbarBrand>
            </Navbar>
            <div className={styles.containerVerde}>
                <div className={styles.title}>
                    Olá, seja bem-vindo(a)!
                </div>
                <div className={styles.info}>
                    <p>Responda as perguntas abaixo</p>
                </div>
            </div>
            <div className={styles.containerBranco}>
                <div className={styles.progress}>
                    Seu progresso:
                </div>
                <div className={styles.divProgress}>
                    {/**
                    <div className={styles.progressNum}>
                        {perguntaAtual > qtdPerguntas ? qtdPerguntas : perguntaAtual} de {qtdPerguntas}
                    </div>
                     */}
                    <Progress max={qtdPerguntas} value={perguntaAtual} className={styles.progressBar} color="success" />
                </div>
                <div className={styles.balaoPergunta}>
                    <div className={styles.pergunta}>
                        {perguntaAtual > qtdPerguntas ? handleSubmit(respostas) : perguntas[perguntaAtual - 1]?.pergunta ?? <p>Atualizando pergunta</p>}
                    </div>
                </div>
                {}
                <div className={styles.balaoResposta}>
                    <div className={styles.resposta}>
                        {respostaSelecionanda}
                    </div>
                </div>
            </div>
            <div className={styles.containerCinza}>
                Sua resposta:
                <Respostas tipo={perguntas[perguntaAtual - 1]?.tipo_resposta ?? 0} getResposta={getResposta} />
                <Button
                    className={styles.buttonEnviar}
                    size="sm"
                    disabled={respostaSelecionanda ? false : true}
                    onClick={() => salvaResposta()}
                >
                    Enviar
                </Button>
            </div>
        </div >
    );
}

export default Perguntas;