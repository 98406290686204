import React from "react";
import styles from "./LoadingComponent.module.css";
import logoHB4 from '../../assets/images/hb4_principal.png'
import { Spinner } from 'reactstrap';

function LoadingComponent() {

    return (
        <div className={styles.mainDiv}>
            <div>
                <div className={styles.divImage}>
                    <img src={logoHB4} className={styles.image} />
                </div>

                <div className={styles.divText}>
                    Carregando
                </div>
                <div className={styles.divSpinner}>
                    <Spinner
                        size=""
                        className={styles.spinner}>
                    </Spinner>
                </div>

            </div>
        </div>
    );
};

export default LoadingComponent;