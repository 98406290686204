import React, { useContext, useState } from 'react';
import styles from './index.module.css';
import { Context } from '../../context/authContext';
import logoHB4 from '../../assets/images/hb4_principal.png'
import { Form, FormGroup, Label, Input, Button, InputGroup } from 'reactstrap';
import { useFormik } from 'formik';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ModalComponent from '../../components/Modal/ModalComponent';

function Login() {

    const { authenticated, handleClickLogin, errorLogin } = useContext(Context);

    const showPassword = () => {
        let inputPassword = document.getElementsByClassName(styles.inputPassword);
        if (inputPassword.senha.type === "password") {
            setShowPass("true")
            setRenderPass(<AiOutlineEyeInvisible className={styles.passwordIcon} onClick={showPassword} />)
            inputPassword.senha.type = "text";
        } else {
            setShowPass("false");
            setRenderPass(<AiOutlineEye className={styles.passwordIcon} onClick={showPassword} />)
            inputPassword.senha.type = "password";
        }

    }

    const [showPass, setShowPass] = useState("false");
    const [renderPass, setRenderPass] = useState(<AiOutlineEye className={styles.passwordIcon} onClick={showPassword} />);

    const formik = useFormik({
        initialValues: {
            email: '',
            senha: '',
        },
        validateOnBlur: true,
        validate: values => {
            const errors = {};

            const email_teste = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
            if (!values.email) {
                errors.email = "O campo é obrigatório.";
            } else if (!email_teste.test(values.email)) {
                errors.email = "Digite um e-mail válido nome@email.com."
            }

            //validar requisitos mínimos
            const senha_teste = /^(?=.*\d)(?=.*[a-z]|[A-Z])[0-9a-zA-Z$*&@#]{8,}$/;
            if (!values.senha) {
                errors.senha = "O campo é obrigatório.";
            }

            return errors;
        },
        onSubmit: values => {
            handleClickLogin(values);
        }
    });

    return (

        <div className={styles.mainDiv}>
            {errorLogin ? <ModalComponent mensagem="Usuário ou senha incorreto" icon="Erro" /> : null}
            <div className={styles.container}>
                <div className={styles.divImage}>
                    <img src={logoHB4} className={styles.image} />
                </div>
                <Form inline onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                        <Label className={styles.labelUser} for="email">
                            Usuário
                            </Label>
                        <Input
                            id="email"
                            bsSize="sm"
                            name="email"
                            placeholder="Usuário"
                            type="email"
                            className={formik.errors.email ? styles.errorInput : styles.inputUser}
                            autoFocus
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.errors.email ? <div className={styles.errorDiv}>{formik.errors.email}</div> : null}
                    </FormGroup>

                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                        <Label className={styles.labelPassword} for="password">
                            Senha
                            </Label>

                        <InputGroup>
                            <Input
                                id="senha"
                                bsSize="sm"
                                name="senha"
                                placeholder="Senha"
                                type="password"
                                className={formik.errors.senha ? styles.errorInput : styles.inputPassword}
                                onChange={formik.handleChange}
                                value={formik.values.senha}
                            />
                            <Button className={styles.buttonPassword} type="button" showPass={showPass}>
                                {renderPass}
                            </Button>
                        </InputGroup>
                        {formik.errors.senha ? <div className={styles.errorDiv}>{formik.errors.senha}</div> : null}
                    </FormGroup>
                    <Input type="checkbox" className={styles.checkBox} />
                    {' '}
                    <Label check className={styles.rememberMe}>
                        Lembre de mim
                    </Label>
                    <Button className={styles.button} type="submit">
                        Acessar
                    </Button>

                    <div className={styles.divForgotPassword}>
                        <a href="#" className={styles.forgotPassword}>Esqueceu sua senha?</a>
                    </div>
                </Form>

            </div>
        </div >

    );
}

export default Login;
