import React, { useContext } from "react";
import { Routes as ReactRoutes, Route, Navigate, Outlet } from "react-router";
import { Context } from "./context/authContext";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ProfissionalSaude from "./pages/ProfissionalSaude";
import CadastroProfissionalSaude from "./pages/CadastroProfissionaisSaude";
import VisualizarProfissionalSaude from './pages/VisualizarProfissionalSaude';
import EditarProfissionalSaude from './pages/EditarProfissionalSaude';
import Pacientes from "./pages/Pacientes"
import CadastroPaciente from './pages/CadastroPacientes';
import VisualizarPaciente from './pages/VisualizarPaciente';
import EditarPaciente from './pages/EditarPaciente';
import Recepcionistas from './pages/Recepcionistas';
import CadastroRecepcionista from './pages/CadastroRecepcionista';
import VisualizarRecepcionista from './pages/VisualizarRecepcionista';
import EditarRecepcionista from './pages/EditarRecepcionista';
import LoadingComponent from './components/Loading/LoadingComponent';
import Questionarios from './pages/Questionarios';
import VisualizarQuestionario from './pages/VisualizarQuestionario';
import Perguntas from './pages/Perguntas';

function PrivateRoute() {
    const { authenticated, loading } = useContext(Context);

    if (loading) {
        return <LoadingComponent />;
    }

    return authenticated ? <Outlet /> : <Navigate to="/login" />;
}

function Routes() {
    const { authenticated } = useContext(Context);
    return (
        <ReactRoutes>
            <Route path="/" element={authenticated ? <Home /> : <Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/perguntas/:id" element={<Perguntas />} />
            <Route element={<PrivateRoute />}>
                <Route index path="/home" element={<Home />} />
                <Route path="/pacientes" element={<Pacientes />} />
                <Route path="/cadastroPaciente" element={<CadastroPaciente />} />
                <Route path="/visualizarPaciente/:id" element={<VisualizarPaciente />} />
                <Route path="/editarPaciente/:id" element={<EditarPaciente />} />
                <Route path="/profissionaisSaude" element={<ProfissionalSaude />} />
                <Route path="/cadastroProfissionalSaude" element={<CadastroProfissionalSaude />} />
                <Route path="/visualizarProfissional/:id" element={<VisualizarProfissionalSaude />} />
                <Route path="/editarProfissional/:id" element={<EditarProfissionalSaude />} />
                <Route path="/recepcionistas" element={<Recepcionistas />} />
                <Route path="/cadastroRecepcionista" element={<CadastroRecepcionista />} />
                <Route path="/visualizarRecepcionista/:id" element={<VisualizarRecepcionista />} />
                <Route path="/editarRecepcionista/:id" element={<EditarRecepcionista />} />
                <Route path="/questionarios" element={<Questionarios />} />
                <Route path="/visualizarQuestionario/:id" element={<VisualizarQuestionario />} />
            </Route>
        </ReactRoutes>
    );
}

export default Routes;